.message {
  display: flex;
  padding-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #000;
}

.message.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.message.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.message.warning {
  color: #ffc107;
  background-color: lightyellow;
  border-color: lightgoldenrodyellow;
}
