/* Geral */

.set_page_size {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 2000px) {
  .set_page_size {
    min-height: 73vh;
  }
}

section[name="bg-blue"] {
  background-color: #043454;
  color: white;
}

.dosimagem-color {
  color: #043454;
}

@media screen and (max-width: 780px) {
  div[name="small_margin"] {
    margin: auto 1.5em;
  }
  p[name="small_margin"] {
    margin: auto 1.5em;
  }
}

/* Ipad */
@media screen and (max-width: 900px) and (max-height: 1200px) {
  .set_page_size {
    min-height: 78vh;
  }
}

section div[name="setMargin"] {
  margin: auto 18vw;
}

@media screen and (max-width: 1400px) {
  section div[name="setMargin"] {
    margin: auto 9vw;
  }
}

@media screen and (max-width: 700px) {
  section div[name="setMargin"] {
    margin: auto;
  }
}

/* Menu */
@media screen and (max-width: 400px) {
  .navbar-brand img {
    width: 250px;
    height: 85px;
  }
}

/* Home */
section section {
  margin-bottom: 15px;
  text-align: center;
  padding: 30px;
}

/* Loading indicator */
.loading {
  animation: rotation 1.75s infinite linear;
  max-width: 5vw;
  max-height: 8vh;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* Button color */
button[name="button"],
a[name="button"] {
  background-color: #043454;
  border: #043454;
}

/* Forms */
.dosiform {
  background-color: rgba(184, 192, 198, 0.5);
  border-radius: 0.9rem;
}

img[name="form-img-logo"] {
  max-width: 50%;
}

@media screen and (max-width: 1400px) {
  img[name="form-img-logo"] {
    max-width: 65%;
  }
}

@media screen and (max-width: 1200px) {
  img[name="form-img-logo"] {
    max-width: 75%;
  }
}

@media screen and (max-width: 800px) {
  img[name="form-img-logo"] {
    max-width: 100%;
  }
}

/* Text */
.text-justify {
  text-align: justify;
}

/* Phone number input */
.input-phone-number input {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.input-phone-number input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
