#footer {
    background-color: #043454;
    border-top: 1px solid white;
    padding-top: 1px;
    color: white;
    text-align: center;        
}

#social-media-footer {
    padding: 5px;
}

#social-media-footer a {
    color: white;
    padding-right: 15px;            
}

#social-media-footer a:hover {
    color: #70baeb;
}

#useful-links a {    
    font-size: 16px;
    color: white;
    text-decoration: none;
}

#company-address {
    font-size: 12px;
    text-align: left;    
}

#copyright {        
    background-color: rgba(255, 255, 255, 0.3);
}

#copyright img {
    max-width: 50px;
    max-height: 50px;
}